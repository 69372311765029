import React from 'react';
import { Select } from '@grafana/ui';
import { CellProps } from 'react-table';
import { AVAILABLE_ES_VERSIONS } from 'provisioning/opensearch';
import { InstallableData } from 'types/types';

export const ESVersionCell = ({ row, setCellState }: CellProps<InstallableData>) =>
  row.original?.openSearchInfo?.serverless ? (
    <>Serverless</>
  ) : (
    <Select
      aria-label={`OpenSearch version for ${row.original.resourceId}`}
      width={20}
      options={AVAILABLE_ES_VERSIONS}
      disabled={!!row.original.openSearchInfo?.esVersion}
      placeholder="Select Version"
      onChange={(value) => setCellState([row.id], 'esVersion', value.value)}
      value={AVAILABLE_ES_VERSIONS.find(
        (v) =>
          v.value?.version === row.original.openSearchInfo?.esVersion?.version &&
          v.value?.flavor === row.original.openSearchInfo?.esVersion?.flavor
      )}
    />
  );
