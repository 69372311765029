import { DataSourceSettings } from '@grafana/data';
import {
  AWSDataSourceJsonData,
  AWSDataSourceProvisioner,
  AWSDataSourceSettings,
  AWSDataSourceType,
  AWSServiceType,
  CreateDataSourceParams,
} from 'types/types';
import { FetchError, getBackendSrv } from '@grafana/runtime';

export const serviceToDSMap: any = {
  [AWSServiceType.CloudWatch]: { name: 'Amazon CloudWatch', type: AWSDataSourceType.CloudWatch },
  [AWSServiceType.Elasticsearch]: { name: 'Elasticsearch', type: AWSDataSourceType.Elasticsearch },
  [AWSServiceType.OpenDistro]: { name: 'Elasticsearch', type: AWSDataSourceType.OpenDistro },
  [AWSServiceType.OpenSearch]: { name: 'OpenSearch', type: AWSDataSourceType.OpenSearch },
  [AWSServiceType.Prometheus]: { name: 'Prometheus', type: AWSDataSourceType.Prometheus },
  [AWSServiceType.SiteWise]: { name: 'AWS IoT SiteWise', type: AWSDataSourceType.SiteWise },
  [AWSServiceType.TimeStream]: { name: 'Amazon Timestream', type: AWSDataSourceType.TimeStream },
  [AWSServiceType.XRay]: { name: 'AWS X-Ray', type: AWSDataSourceType.XRay },
  [AWSServiceType.Athena]: { name: 'AWS Athena', type: AWSDataSourceType.Athena },
  [AWSServiceType.Redshift]: { name: 'AWS Redshift', type: AWSDataSourceType.Redshift },
  [AWSServiceType.TwinMaker]: { name: 'AWS IoT TwinMaker', type: AWSDataSourceType.TwinMaker },
};

export function buildARN(accountId: string, roleName: string): string {
  return `arn:aws:iam::${accountId}:role/${roleName}`;
}

export function getServiceDataSources<T extends AWSDataSourceJsonData>(
  serviceType: string,
  dataSources: Array<AWSDataSourceSettings<T>>
): Array<AWSDataSourceSettings<T>> {
  if (!dataSources) {
    return [];
  }

  const service = serviceToDSMap[serviceType];
  const provisionedByApp = ({ type, jsonData: { provisionedBy } }: DataSourceSettings<T>) =>
    provisionedBy === AWSDataSourceProvisioner.ProvisioningApp && type === service.type;

  return dataSources.filter(provisionedByApp);
}

const urlPattern = /^[^:]*:\/\//;

function fixDataSourceUrl(url?: string) {
  if (url && !urlPattern.test(url)) {
    return `https://${url}`;
  }
  return url;
}

export function createDataSource(
  type: AWSDataSourceType,
  name: string,
  settings: AWSDataSourceSettings<AWSDataSourceJsonData>,
  installedDSNames: Set<string>
) {
  const { url, jsonData } = settings;
  if (jsonData) {
    jsonData.provisionedBy = AWSDataSourceProvisioner.ProvisioningApp;
  }

  const dsSettings: AWSDataSourceSettings<AWSDataSourceJsonData> = {
    ...settings,
    url: fixDataSourceUrl(url),
    access: 'proxy',
    name,
    type,
    jsonData: jsonData,
  };

  return createDataSourceWithRetryOnConflict(dsSettings, installedDSNames);
}

// TODO: This procedure needs to be fixed in order to avoid multiple API calls but receiving the index instead.
//       However, this quick fix is considered acceptable since this a non-frequent edge case. Also, deprecated
//       function usage needs to be removed.
export async function createDataSourceWithRetryOnConflict(
  dsSettings: AWSDataSourceSettings<AWSDataSourceJsonData>,
  installedDSNames: Set<string>
) {
  while (installedDSNames.has(dsSettings.name || '')) {
    dsSettings.name = nextName(dsSettings.name);
  }

  while (true) {
    try {
      await getBackendSrv()
        .fetch({
          method: 'POST',
          url: `/api/datasources`,
          data: dsSettings,
          showSuccessAlert: true,
          showErrorAlert: false,
        })
        .toPromise();
      break;
    } catch (err) {
      if (!isNameConflictError(err)) {
        throw err;
      }
      dsSettings.name = nextName(dsSettings.name);
    }
  }
}

const isNameConflictError = (err: FetchError) =>
  err.status === 409 && err.statusText === `Conflict` && dataIncludesAlreadyExists(err.data);

const dataIncludesAlreadyExists = (data: any) =>
  !!data.includes ? data.includes(`already exists`) : data.message.includes(`already exists`);

const nextName = (prevName?: string) => {
  if (!prevName) {
    return prevName;
  }

  const regex = /\([0-9]+\)/;
  const match = prevName.match(regex);
  if (!match) {
    return `${prevName} (1)`;
  }

  const prevNumStr = match[0].substring(1, match[0].length - 1);
  const prevNum = parseInt(prevNumStr, 10);
  return prevName.replace(match[0], `(${prevNum + 1})`);
};

export function shouldSetAssumeRole(params: CreateDataSourceParams, workspaceAccountId: string): boolean {
  return (params.account && params.account.id !== workspaceAccountId) ?? false;
}
