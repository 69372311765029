import React from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme } from '@grafana/data';
import { Alert, useStyles } from '@grafana/ui';

export const TwinMakerBox = () => {
  const styles = useStyles(getStyles);

  return (
    <div className={styles.serviceSection}>
      <Alert title="Dashboard Role" severity="info">
        A dashboard role must be configured in order to connect a workspace. The dashboard role should be tagged in the
        the workspace under the key &ldquo;TwinMakerDashboardRole&ldquo;. See more about creating a dashboard IAM role
        <a href="https://docs.aws.amazon.com/iot-twinmaker/latest/guide/dashboard-IAM-role.html"> here.</a>
      </Alert>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme) => {
  return {
    serviceSection: css`
      margin-top: ${theme.spacing.xl};
      margin-bottom: ${theme.spacing.xl};
    `,
  };
};
