import { AWSDataSourceSettings, AWSDataSourceType, AWSDataSourceJsonData, CreateDataSourceParams } from 'types/types';
import { createDataSource, serviceToDSMap } from './datasource';

export interface TwinMakerJsonData extends AWSDataSourceJsonData {
  workspaceId: string;
  assumeRoleArn: string;
}

export type TwinMakerDataSourceSettings = AWSDataSourceSettings<TwinMakerJsonData>;

export interface CreateTwinMakerDataSourceParams extends CreateDataSourceParams {
  workspaceId: string;
  dashboardRole: string;
}

export function createTwinMakerDataSource(params: CreateTwinMakerDataSourceParams, installedDSNames: Set<string>) {
  const dsSettings: TwinMakerDataSourceSettings = {
    jsonData: {
      authType: 'ec2_iam_role',
      defaultRegion: params.region,
      workspaceId: params.workspaceId,
      assumeRoleArn: params.dashboardRole,
    },
  };

  const dsName = `${serviceToDSMap.twinmaker.name} ${params.region} ${params.workspaceId} ${params.dashboardRole}`;

  return createDataSource(AWSDataSourceType.TwinMaker, dsName, dsSettings, installedDSNames);
}
