import React, { SyntheticEvent, ReactNode } from 'react';
import { ServiceInfo } from 'types/types';
import { ASSETS_ROOT } from '../../constants';
import { TabComponentProps } from '../Navigation/Tabs';
import { LinkButton, useStyles } from '@grafana/ui';
import { css, cx } from '@emotion/css';
import { MigrationBox } from '../MigrationBox';
import { getServiceIcon } from '../../utils/icons';

interface CardWrapperProps {
  children: ReactNode;
}

export const Services = ({ services }: TabComponentProps) => {
  const styles = useStyles(getStyles);
  const serviceIcon = useStyles(getServiceIcon);

  const onError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = `${ASSETS_ROOT}/logo.svg`;
  };

  return (
    <div>
      <MigrationBox />
      <section className="card-section card-list-layout-list">
        <ul className="card-list">
          {services.map((service: ServiceInfo) => {
            const href = service.enabled
              ? `/a/aws-datasource-provisioner-app/?tab=${encodeURI('datasources')}&id=${service.id}`
              : undefined;

            const CardWrapper = ({ children }: CardWrapperProps) =>
              href ? (
                <a className={cx('card-item', styles.cardItem)} href={href}>
                  {children}
                </a>
              ) : (
                <div className={cx('card-item', styles.cardItem)}>{children}</div>
              );

            return (
              <li className="card-item-wrapper" key={service.id}>
                <CardWrapper>
                  <div className={cx('card-item-body', styles.cardItemBody)}>
                    <figure className="card-item-figure">
                      <img src={`${ASSETS_ROOT}/${serviceIcon(service.id)}`} alt={service.name} onError={onError} />
                    </figure>
                    <div className={cx('card-item-details', styles.cardItemDetails)}>
                      <div className={cx(styles.serviceName, { [styles.disabled]: !service.enabled })}>
                        {service.name}
                      </div>
                      {!service.enabled && (
                        <div>
                          <LinkButton
                            variant="secondary"
                            href={`https://grafana.com/grafana/plugins/${service.plugin}`}
                            target="_blank"
                            rel="noopener"
                            icon="external-link-alt"
                          >
                            Install now
                          </LinkButton>
                        </div>
                      )}
                    </div>
                  </div>
                </CardWrapper>
              </li>
            );
          })}
        </ul>
      </section>
    </div>
  );
};

const getStyles = () => {
  return {
    noMargin: css`
      margin: 0;
    `,
    cardItem: css`
      display: flex;
      align-items: center;
    `,
    cardItemDetails: css`
      display: flex;
      justify-content: space-between;
      width: 100%;
    `,
    cardItemBody: css`
      flex: auto;
      align-items: center;
    `,
    serviceName: css`
      font-size: 1.28rem;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
    disabled: css`
      color: gray;
    `,
  };
};
